import Utility from '../../../shared/utility.js';
import EncryptUtility from './encrypt-utility';
import AddFavourite from "./addFav";
import breadcrumbComp from '../../common/breadcrumb-comp';
import PrinterDetails from '../../common/printer-details';
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      userId: EncryptUtility.localStorageDecrypt('userID'),
      primaryName: EncryptUtility.localStorageDecrypt('primaryName'),
      isPrinter: true,
      unitIdentifier: '',
      validatedUnitIdent: false,
      isFormValid: false,
      pass: null,
      unitIdentifyValid: false,
      searchPartDetails: false,
      searchOtherDetails: false,
      primaryLabelSize: '',
      primaryPrinterType: '',
      partDetails: [],
      quantity: 1,
      projectData: [],
      selectedProject: '',
      wareHouseData: [],
      selectedWarehouse: '',
      projectSelected: false,
      wareHouseSelected: false,
      selectedLocation: '',
      locationData: [],
      locationSelected: false,
      selectedGrade: '',
      gradeData: [],
      quanFormValidPart: false,
      quanFormValidOther: false,
      gradeSelected: false,
      gradeNoData: false,
      max50Rule: [
        (v) => (v || '').length <= 50 || "Max Length of 50 character",
        (v) => !!v || 'Field is required'
      ],
      max5Rule: [
        (v) => (v || '').length <= 5 || "Max Length of 5 character",
        (v) => !!v || 'Field is required'
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
  },
  methods: {
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event)
      return value
    },
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route)
    },
    //Reset Function
    resetFunction() {
      this.unitIdentifier = '';
      this.validatedUnitIdent = false;
      this.pass = null;
      this.searchPartDetails = false;
      this.$refs.UnitIdentifierForm.resetValidation();
      this.$refs.QuantityFormPart.resetValidation();
      this.$refs.QuantityFormOther.resetValidation();
      this.partDetails = [];
      this.primaryLabelSize = '';
      this.primaryPrinterType = '';
      this.quantity = 1;
      this.searchOtherDetails = false;
      this.projectData = [];
      this.selectedProject = '';
      this.wareHouseData = [];
      this.selectedProject = '';
      this.projectSelected = false;
      this.wareHouseSelected = false;
      this.selectedLocation = '';
      this.locationData = [];
      this.locationSelected = false;
      this.selectedGrade = '';
      this.gradeData = [];
      this.quanFormValidPart = false;
      this.quanFormValidOther = false;
      this.isFormValid = false;
      this.selectedWarehouse = '';
      this.gradeSelected = false;
      this.gradeNoData = false;
    },
    //Searching the Identifier
    searchIdentifer() {
      this.onSearchClick();
    },
    //Search button click event
    onSearchClick() {
      if (!this.$refs.UnitIdentifierForm.validate()) return false;
      this.pass = 1;
      this.partDetails = [];
      this.projectData = [];
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchUnitIdentifier = {
        pass: parseInt(this.pass),
        ware_key: 0,
        qty: parseInt(this.quantity),
        UserId: parseInt(this.userId),
        proj_key: 0,
        locx: '',
        cos_grade: '',
        unit: this.unitIdentifier,
        bin_lpn: '',
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
      };
      this.axios
        .post('/ut/box_label_reprint', searchUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.isFormValid = true;
            if (responsedata.bc == '1') {
              this.partDetails = responsedata;
              this.searchPartDetails = true;
            } else {
              this.searchOtherDetails = true;
              this.projectData = responsedata.Projects;
            }
            this.validatedUnitIdent = true;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Project Selected
    projectChange() {
      this.pass = 2;
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchUnitIdentifier = {
        pass: parseInt(this.pass),
        ware_key: 0,
        qty: parseInt(this.quantity),
        UserId: parseInt(this.userId),
        proj_key: parseInt(this.selectedProject),
        locx: '',
        cos_grade: '',
        unit: this.unitIdentifier,
        bin_lpn: '',
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
      };
      this.axios
        .post('/ut/box_label_reprint', searchUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.projectSelected = true;
            this.wareHouseData = responsedata.Ware;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Warehouse Selected
    wareHouseChange() {
      this.locationData = [];
      this.locationData = this.wareHouseData.filter((obj) => obj.ware_id === this.selectedWarehouse);
      this.wareHouseSelected = true;
    },
    //Location Selected
    locationChange() {
      this.gradeData = [];
      this.gradeData = this.wareHouseData.filter((obj) => obj.ware_id === this.selectedWarehouse);
      if (this.gradeData[0].cos_grade == '  ' || this.gradeData[0].cos_grade == undefined || this.gradeData[0].cos_grade == ' ') {
        this.gradeSelected = true;
        this.gradeNoData = true;
      } else {
        if (this.gradeData.length == 1) {
          this.locationSelected = true;
          this.selectedGrade = this.gradeData[0].cos_grade;
          this.gradeSelected = true;
        } else {
          this.locationSelected = true;
        }
      }
    },
    //Quantity Submit click event for Part Scenario
    quantitySubmitPart() {
      this.pass = 3;
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let submitUnitIdentifier = {
        pass: parseInt(this.pass),
        ware_key: 0,
        qty: parseInt(this.quantity),
        UserId: parseInt(this.userId),
        proj_key: 0,
        locx: this.selectedLocation,
        cos_grade: this.selectedGrade,
        unit: this.unitIdentifier,
        bin_lpn: '',
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
      };
      this.axios
        .post('/ut/box_label_reprint', submitUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            PrinterDetails.printLabelData(responseData.Label[0].label, this.primaryName);
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //Grade Change
    gradeChange() {
      this.gradeSelected = true;
    },
    //Quantity Submit click event for Other Scenario
    quantitySubmitOther() {
      this.pass = 3;
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let submitUnitIdentifier = {
        pass: parseInt(this.pass),
        ware_key: parseInt(this.selectedWarehouse),
        qty: parseInt(this.quantity),
        UserId: parseInt(this.userId),
        proj_key: parseInt(this.selectedProject),
        locx: this.selectedLocation,
        cos_grade: this.selectedGrade,
        unit: this.unitIdentifier,
        bin_lpn: '',
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
      };
      this.axios
        .post('/ut/box_label_reprint', submitUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            responseData.Label.forEach((element) => {
              PrinterDetails.printLabelData(element.label, this.primaryName);
            });
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
